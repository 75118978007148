module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-transition-link/gatsby-browser.js'),
      options: {"plugins":[],"layout":"C:\\Users\\user\\Desktop\\138-pyramids-main\\src\\components\\Layout.jsx","injectPageProps":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-scroll-reveal/gatsby-browser.js'),
      options: {"plugins":[],"threshold":0.3,"interval":200,"delay":100},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
